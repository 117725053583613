import React, { useState, useEffect, useContext } from 'react';
import { Switch, Route } from "react-router-dom";
import { WalletContext } from './WalletContext';
import Dashboard from './Pages/Dashboard'
import Club from './Pages/Club'
import Squad from './Pages/Squad'
import Stadium from './Pages/Stadium'
import TransferMarket from './Pages/Market'
import Game from './Game';
import Frontpage from './Pages'

const App = (props) => {

  const [wallet, networkId, networkStatus, isSkale, shortName, networkName, walletBallance, tokenBallance, tokenAdd, ethPrice] = useContext(WalletContext)

  return (
    <>
      <Switch>
        <Route exact path="/"><Frontpage wallet={wallet} networkId={networkId} networkStatus={networkStatus} isSkale={isSkale} shortName={shortName} tokenAdd={tokenAdd} networkName={networkName} walletBallance={walletBallance} tokenBallance={tokenBallance} ethPrice={ethPrice} /></Route>
        <Route exact path="/Dashboard"><Dashboard wallet={wallet} networkId={networkId} networkStatus={networkStatus} isSkale={isSkale} shortName={shortName} tokenAdd={tokenAdd} networkName={networkName} walletBallance={walletBallance} tokenBallance={tokenBallance} ethPrice={ethPrice} /></Route>
        <Route exact path="/Club"><Club wallet={wallet} networkId={networkId} networkStatus={networkStatus} isSkale={isSkale} shortName={shortName} tokenAdd={tokenAdd} networkName={networkName} walletBallance={walletBallance} tokenBallance={tokenBallance} ethPrice={ethPrice} /></Route>
        <Route exact path="/Squad"><Squad wallet={wallet} networkId={networkId} networkStatus={networkStatus} isSkale={isSkale} shortName={shortName} tokenAdd={tokenAdd} networkName={networkName} walletBallance={walletBallance} tokenBallance={tokenBallance} ethPrice={ethPrice} /></Route>
        <Route exact path="/Stadium"><Stadium wallet={wallet} networkId={networkId} networkStatus={networkStatus} isSkale={isSkale} shortName={shortName} tokenAdd={tokenAdd} networkName={networkName} walletBallance={walletBallance} tokenBallance={tokenBallance} ethPrice={ethPrice} /></Route>
        <Route exact path="/TransferMarket"><TransferMarket wallet={wallet} networkId={networkId} networkStatus={networkStatus} isSkale={isSkale} shortName={shortName} tokenAdd={tokenAdd} networkName={networkName} walletBallance={walletBallance} tokenBallance={tokenBallance} ethPrice={ethPrice} /></Route>
        <Route exact path="/Game"><Game wallet={wallet} networkId={networkId} networkStatus={networkStatus} isSkale={isSkale} shortName={shortName} tokenAdd={tokenAdd} networkName={networkName} walletBallance={walletBallance} tokenBallance={tokenBallance} ethPrice={ethPrice} /></Route>
        <Route exact path="/Game/:name-:key"><Game wallet={wallet} networkId={networkId} networkStatus={networkStatus} isSkale={isSkale} shortName={shortName} tokenAdd={tokenAdd} networkName={networkName} walletBallance={walletBallance} tokenBallance={tokenBallance} ethPrice={ethPrice} /></Route>
        <Route exact path='*'><Dashboard wallet={wallet} networkId={networkId} networkStatus={networkStatus} isSkale={isSkale} shortName={shortName} tokenAdd={tokenAdd} networkName={networkName} walletBallance={walletBallance} tokenBallance={tokenBallance} ethPrice={ethPrice} /></Route>
      </Switch>
    </>
  )
}

export default App