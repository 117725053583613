import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";
import './index.css';
import App from './App';
import { WalletProvider } from './WalletContext';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('kickoff'));
root.render(
  <Router>
    <WalletProvider>
      <App />
    </WalletProvider>
  </Router>
);

serviceWorkerRegistration.register();
reportWebVitals();
