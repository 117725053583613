import React, { useState, useEffect, createContext } from 'react';
import { ethers } from "ethers";
import tokenAbi from "./tokenAbi.json";

export const WalletContext = createContext();

export const WalletProvider = props => {

    const [networkId, setNetworkId] = useState(0)
    const [accounts, setAccounts] = useState([])
    const [wallet, setWallet] = useState(null)

    const [networkStatus, setNetworkStatus] = useState(null)
    const [isSkale, setIsSkale] = useState(0)
    const [networkName, setNetworkName] = useState("Ethereum")
    const [shortName, setShortName] = useState("ETH")
    const [tokenAdd, setTokenAdd] = useState("0x0fF4Be4912b5E1859c40168562C158b718a00131")
    const [ethPrice, setEthPrice] = useState("10000.0")
    const [walletBallance, setWalletBallance] = useState(0)
    const [tokenBallance, setTokenBallance] = useState(0)

    useEffect(() => {

        const handleMetaMaskChange = () => {
            window.location.href = "/";
        }

        const loadWeb3 = async () => {
            if (window.ethereum) {
                window.web3 = new ethers.providers.Web3Provider(window.ethereum)
                await window.ethereum.request({ method: 'eth_requestAccounts' });
                loadBlockchainData()
                await window.ethereum.on('message', () => window.location.reload());
                await window.ethereum.on('disconnect', handleMetaMaskChange);
                await window.ethereum.on('accountsChanged', handleMetaMaskChange);
                await window.ethereum.on('chainChanged', handleMetaMaskChange);
            }
            else if (window.web3) {
                window.web3 = new ethers.providers.Web3Provider(window.ethereum)
                loadBlockchainData()
                await window.ethereum.on('message', () => window.location.reload());
                await window.ethereum.on('disconnect', handleMetaMaskChange);
                await window.ethereum.on('accountsChanged', handleMetaMaskChange);
                await window.ethereum.on('chainChanged', handleMetaMaskChange);
            }
            else {
                // window.location.href = "/";
            }
        }

        const switchNetwork = (param) => {
            switch (param) {
                case 1:
                    setNetworkStatus(true);
                    setIsSkale(0);
                    setNetworkName("Ethereum");
                    setShortName("ETH");
                    setTokenAdd("0x0fF4Be4912b5E1859c40168562C158b718a00131");
                    setEthPrice("10000.0");
                    return
                case 56:
                    setNetworkStatus(true);
                    setIsSkale(0);
                    setNetworkName("BNB Chain");
                    setShortName("BNB");
                    setTokenAdd("0x0fF4Be4912b5E1859c40168562C158b718a00131");
                    setEthPrice("1000.0");
                    return
                case 43114:
                    setNetworkStatus(true);
                    setIsSkale(0);
                    setNetworkName("AVAX C-Chain");
                    setShortName("AVAX");
                    setTokenAdd("0x0fF4Be4912b5E1859c40168562C158b718a00131");
                    setEthPrice("300.0");
                    return
                case 137:
                    setNetworkStatus(true);
                    setIsSkale(0);
                    setNetworkName("Polygon Network");
                    setShortName("MATIC");
                    setTokenAdd("0x0fF4Be4912b5E1859c40168562C158b718a00131");
                    setEthPrice("4.0");
                    return
                case 250:
                    setNetworkStatus(true);
                    setIsSkale(0);
                    setNetworkName("Fantom Network");
                    setShortName("FTM");
                    setTokenAdd("0x0fF4Be4912b5E1859c40168562C158b718a00131");
                    setEthPrice("2.0");
                    return
                case 592:
                    setNetworkStatus(true);
                    setIsSkale(0);
                    setNetworkName("Astar Network");
                    setShortName("ASTR");
                    setTokenAdd("0x0fF4Be4912b5E1859c40168562C158b718a00131");
                    setEthPrice("1.0");
                    return
                case 17777:
                    setNetworkStatus(true);
                    setIsSkale(0);
                    setNetworkName("EOS EVM");
                    setShortName("EOS");
                    setTokenAdd("0x0fF4Be4912b5E1859c40168562C158b718a00131");
                    setEthPrice("50.0");
                    return
                case 8217:
                    setNetworkStatus(true);
                    setIsSkale(0);
                    setNetworkName("Klaytn Mainnet");
                    setShortName("KLAY");
                    setTokenAdd("0x0fF4Be4912b5E1859c40168562C158b718a00131");
                    setEthPrice("10.0");
                    return
                default:
                    setNetworkStatus(false);
                    setIsSkale(0);
                    setNetworkName("Not Valid Network");
                    setShortName("Not Valid Network");
                    setTokenAdd("0x0fF4Be4912b5E1859c40168562C158b718a00131");
                    setEthPrice("0.0");                    
                    return
            }
        }

        const loadBlockchainData = async () => {
            const provider = new ethers.providers.Web3Provider(window.ethereum)

            const accounts = await provider.send("eth_requestAccounts", []);

            const { chainId } = await provider.getNetwork()
            const { name } = await provider.getNetwork()

            const signer = provider.getSigner()
            let address = await signer.getAddress()

            let balanceWei = await provider.getBalance(accounts[0]);
            let balanceEther = await ethers.utils.formatEther(balanceWei, 'ether');

            //if (networkStatus) {
                var contractToken = new ethers.Contract(tokenAdd, tokenAbi, provider);
                var balanceToken = await contractToken.balanceOf(address);
                let balanceTokenEther = await ethers.utils.formatEther(balanceToken, 'ether');

                setWalletBallance(balanceEther)
                setTokenBallance(balanceTokenEther)
            //}

            switchNetwork(chainId)

            setAccounts(accounts)
            setWallet(accounts[0])
            setNetworkId(chainId)
        }

        loadWeb3()
    }, []);

    useEffect(() => {
        setWallet(accounts[0])
    }, [wallet])

    return (
        <WalletContext.Provider value={[wallet, networkId, networkStatus, isSkale, shortName, networkName, walletBallance, tokenBallance, tokenAdd, ethPrice]}>
            {props.children}
        </WalletContext.Provider>
    )
}