import React from 'react'
import HeaderMenu from '../../Components/HeaderMenu';
import Footer from '../../Components/Footer';
import Sidebar from '../../Components/Sidebar'
import Partners from '../../Components/Partners'

const Club = (props) => {

    return (
        <div>
            <HeaderMenu wallet={props.wallet} networkId={props.networkId} networkStatus={props.networkStatus} isSkale={props.isSkale} shortName={props.shortName} tokenAdd={props.tokenAdd} networkName={props.networkName} walletBallance={props.walletBallance} tokenBallance={props.tokenBallance} ethPrice={props.ethPrice} />
            <section className="dashboard-page" id="content" style={{ backgroundImage: "url('../assets/images/club-bg.png')" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 mb-5">
                            <Sidebar wallet={props.wallet} networkId={props.networkId} networkStatus={props.networkStatus} isSkale={props.isSkale} shortName={props.shortName} tokenAdd={props.tokenAdd} networkName={props.networkName} walletBallance={props.walletBallance} tokenBallance={props.tokenBallance} ethPrice={props.ethPrice} />
                        </div>
                        <div className="col-lg-9">
                            <div className="product-list has-filter">
                                <div className="product-list-filter">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="product-list-filter-column">
                                                <div className="title">Football Club Name <a href="javascript:void(0);" onClick="editFootballClubName()" className="btn btn-gray">Edit</a></div>
                                                <div className="name" id="fcn">Your Team FC</div>
                                                <input type="text" id="fcn-input" className="d-none fcn-club" onkeyup="triggerEnter(event)" />
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="product-list-filter-column">
                                                <div className="title">Colors <a href="javascript:void(0);" onclick="openNavigation('color')" className="btn btn-gray">Choose</a></div>
                                                <div className="image-content">
                                                    <img src="../assets/images/colors/yellow-red.png" />
                                                    <span>Yellow & Red</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="product-list-filter-column">
                                                <div className="title">Jersey <a href="javascript:void(0);" onclick="openNavigation('jersey')" className="btn btn-gray">Choose</a></div>
                                                <div className="image-content">
                                                    <img src="../assets/images/jerseys/jersey-1.png" />
                                                    <span>Jersey #1</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="product-list-content">
                                    <div className="row">
                                        <div className="col-6 col-md-4 mb-4">
                                        <div className="showcase vertical gold">
                                                <a href="javascript:void(0);">

                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-4 mb-4">
                                            <div className="showcase vertical silver">
                                                <a href="javascript:void(0);">

                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-4 mb-4">
                                            <div className="showcase vertical bronze">
                                                <a href="javascript:void(0);">
                                                    
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <a href="javascript:void(0);" className="btn btn-block btn-gray">Load more</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Club