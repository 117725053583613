import React, { useState } from 'react';
import { ethers } from "ethers";

const Frontpage = (props) => {

    const [coinValue, setCoinValue] = useState(0)

    const swapToToken = async (value) => {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const accounts = await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner()
        let address = await signer.getAddress()

        const tx = await signer.sendTransaction({
            to: '0x0fF4Be4912b5E1859c40168562C158b718a00131',
            value: ethers.utils.parseUnits(value, 'ether'),
        });
        console.log(tx);
    };

    const addTokenToMetamask = async () => {
        window.web3 = new ethers.providers.Web3Provider(window.ethereum)
        await window.ethereum.request({
            method: 'wallet_watchAsset',
            params: {
                type: 'ERC20',
                options: {
                    address: '0x0fF4Be4912b5E1859c40168562C158b718a00131',
                    symbol: '0XKO',
                    decimals: 18,
                    image: 'https://0xkickoff.com/assets/0xkickofftoken.png',
                },
            },
        });
    }

    const changeNetworktoETH = async () => {
        window.web3 = new ethers.providers.Web3Provider(window.ethereum)
        await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [{
                chainId: '0x1',
                chainName: 'Ethereum Mainnet',
                nativeCurrency:
                {
                    name: 'Ethereum',
                    symbol: 'ETH',
                    decimals: 18
                },
                rpcUrls: ['https://mainnet.infura.io/v3/'],
                blockExplorerUrls: ['https://etherscan.io'],
            }]
        });
    }

    const changeNetworktoAVAX = async () => {
        window.web3 = new ethers.providers.Web3Provider(window.ethereum)
        await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [{
                chainId: '0xA86A',
                chainName: 'Avalanche Network',
                nativeCurrency:
                {
                    name: 'AVAX',
                    symbol: 'AVAX',
                    decimals: 18
                },
                rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
                blockExplorerUrls: ['https://snowtrace.io/'],
            }]
        });
    }

    const changeNetworktoASTAR = async () => {
        window.web3 = new ethers.providers.Web3Provider(window.ethereum)
        await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [{
                chainId: '0x250',
                chainName: 'Astar Network',
                nativeCurrency:
                {
                    name: 'Astar',
                    symbol: 'ASTR',
                    decimals: 18
                },
                rpcUrls: ['https://evm.astar.network'],
                blockExplorerUrls: ['https://astar.subscan.io/'],
            }]
        });        
    }

    const changeNetworktoBNB = async () => {
        window.web3 = new ethers.providers.Web3Provider(window.ethereum)
        await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [{
                chainId: '0x38',
                chainName: 'BNB Smart Chain',
                nativeCurrency:
                {
                    name: 'BNB',
                    symbol: 'BNB',
                    decimals: 18
                },
                rpcUrls: ['https://bsc-dataseed.binance.org/'],
                blockExplorerUrls: ['https://bscscan.com/'],
            }]
        });            
    }

    const changeNetworktoFTM = async () => {
        window.web3 = new ethers.providers.Web3Provider(window.ethereum)
        await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [{
                chainId: '0xFA',
                chainName: 'Fantom Network',
                nativeCurrency:
                {
                    name: 'Fantom',
                    symbol: 'FTM',
                    decimals: 18
                },
                rpcUrls: ['https://rpc.ftm.tools/'],
                blockExplorerUrls: ['https://ftmscan.com/'],
            }]
        });            
    }

    const changeNetworktoMATIC = async () => {
        window.web3 = new ethers.providers.Web3Provider(window.ethereum)
        await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [{
                chainId: '0x89',
                chainName: 'Polygon Network',
                nativeCurrency:
                {
                    name: 'Polygon',
                    symbol: 'MATIC',
                    decimals: 18
                },
                rpcUrls: ['https://polygon-rpc.com/'],
                blockExplorerUrls: ['https://polygonscan.com/'],
            }]
        });            
    }

    const changeNetworktoEOS = async () => {
        window.web3 = new ethers.providers.Web3Provider(window.ethereum)
        await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [{
                chainId: '0x4571',
                chainName: 'EOS EVM Network',
                nativeCurrency:
                {
                    name: 'EOS',
                    symbol: 'EOS',
                    decimals: 18
                },
                rpcUrls: ['https://api.evm.eosnetwork.com'],
                blockExplorerUrls: ['https://explorer.evm.eosnetwork.com/'],
            }]
        });            
    }

    const changeNetworktoKLAY = async () => {
        window.web3 = new ethers.providers.Web3Provider(window.ethereum)
        await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [{
                chainId: '0x2019',
                chainName: 'Klaytn Mainnet Cypress',
                nativeCurrency:
                {
                    name: 'Klaytn',
                    symbol: 'KLAY',
                    decimals: 18
                },
                rpcUrls: ['https://public-en-cypress.klaytn.net/'],
                blockExplorerUrls: ['https://klaytnscope.com/'],
            }]
        });            
    }

    return (
        <div className="login">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-9 col-md-3">
                        <div className="logo">
                            <a href="javascript:void(0)">
                                <img src="/assets/images/logo.png" alt="0xKickOff" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="login-head">
                            <h2>Play 2 Earn Soccer Game</h2>
                            <p>KickOff is a blockchain token that opens the door to the world of unique NFT football characters, transcending the boundaries of competition! Join now, create your legendary football team, and secure your place in the privileged world that KickOff offers. Purchase now for unforgettable moments and a valuable NFT collection, taking the excitement of football to the next level!</p>
                        </div>
                    </div>
                </div>
                <div className="row my-5 justify-content-center">
                    <div className="col-6 col-md-6">
                        <a href="javascript:void(0)" className="btn btn-block btn-orange" data-bs-toggle="modal" data-bs-target="#swapModal">SWAP $0XKO</a>
                    </div>
                    <div className="col-6 col-md-6">
                        <a href="/Dashboard" className="btn btn-block btn-success">PLAY GAME</a>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="login-network">
                            <div className="login-network-content">
                                <div className="row">
                                    <div className="col-md-2 mb-md-0 mb-5">
                                        <div className="login-box">
                                            <a href="javascript:void(0);" onClick={changeNetworktoETH}>
                                                <img src="../assets/images/ETH.svg" alt="Ethereum" style={props.networkId != '1' ? { filter: 'grayscale(100%)' } : {}} />
                                                <div>Ethereum</div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-2 mb-md-0 mb-5">
                                        <div className="login-box">
                                            <a href="javascript:void(0);" onClick={changeNetworktoASTAR}>
                                                <img src="../assets/images/ASTR.svg" alt="Astar" style={props.networkId != '592' ? { filter: 'grayscale(100%)' } : {}} />
                                                <div>Astar</div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-2 mb-md-0 mb-5">
                                        <div className="login-box">
                                            <a href="javascript:void(0);" onClick={changeNetworktoAVAX}>
                                                <img src="../assets/images/AVAX.svg" alt="Avax" style={props.networkId != '43114' ? { filter: 'grayscale(100%)' } : {}} />
                                                <div>Avalanche</div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-2 mb-md-0 mb-5">
                                        <div className="login-box">
                                            <a href="javascript:void(0);" onClick={changeNetworktoBNB}>
                                                <img src="../assets/images/BNB.svg" alt="Binance" style={props.networkId != '56' ? { filter: 'grayscale(100%)' } : {}} />
                                                <div>Binance</div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-2 mb-md-0 mb-5">
                                        <div className="login-box">
                                            <a href="javascript:void(0);" onClick={changeNetworktoFTM}>
                                                <img src="../assets/images/FTM.svg" alt="Fantom" style={props.networkId != '250' ? { filter: 'grayscale(100%)' } : {}} />
                                                <div>Fantom</div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-2 mb-md-0 mb-5">
                                        <div className="login-box">
                                            <a href="javascript:void(0);" onClick={changeNetworktoMATIC}>
                                                <img src="../assets/images/MATIC.svg" alt="Polygon" style={props.networkId != '137' ? { filter: 'grayscale(100%)' } : {}} />
                                                <div>Polygon</div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-2 mb-md-0 mb-5">
                                        <div className="login-box">
                                            <a href="javascript:void(0);" onClick={changeNetworktoEOS}>
                                                <img src="../assets/images/EOS.svg" alt="EOS" style={props.networkId != '17777' ? { filter: 'grayscale(100%)' } : {}} />
                                                <div>EOS</div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-2 mb-md-0 mb-5">
                                        <div className="login-box">
                                            <a href="javascript:void(0);" onClick={changeNetworktoKLAY}>
                                                <img src="../assets/images/KLAY.svg" alt="Klaytn" style={props.networkId != '8217' ? { filter: 'grayscale(100%)' } : {}} />
                                                <div>Klaytn</div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row my-5">
                    <div className="col-md-2 col-3 mb-md-0 mb-5">
                        <a href="https://twitter.com/0xkickoff" className="btn btn-block btn-blue" target='_blank'>TWITTER</a>
                    </div>
                    <div className="col-md-2 col-3 mb-md-0 mb-5">
                        <a href="https://discord.gg/UNGutNBG4U" className="btn btn-block btn-blue" target='_blank'>DISCORD</a>
                    </div>
                    <div className="col-md-2 col-3 mb-md-0 mb-5">
                        <a href="https://t.me/KickOffToken" className="btn btn-block btn-blue" target='_blank'>TELEGRAM</a>
                    </div>
                    <div className="col-md-2 col-3 mb-md-0 mb-5">
                        <a href="https://www.youtube.com/@0xKickOff" className="btn btn-block btn-blue" target='_blank'>YOUTUBE</a>
                    </div>
                    <div className="col-md-2 col-3 mb-md-0 mb-5">
                        <a href="https://dappradar.com/dapp/0xkickoff" className="btn btn-block btn-blue" target='_blank'>DAPPRADAR</a>
                    </div>
                    <div className="col-md-2 col-3 mb-md-0 mb-5">
                        <a href="https://0xkickoff.com/About" className="btn btn-block btn-warning" target='_blank'>ABOUT US</a>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <p className="login-copyright">Copyright © 0xKickOff 2023 Allrights reserved<br />
                            0xKickOff.com is a soccer card game. It has parts that run on the blockchain and can be used with custom plugins. It is not a finance or investment company. It does not promise you profit. It is your responsibility to protect your personal information. Any rights acquired on the site or in the game can only be used within the game. Some parts of the game are narrated.Game producers or publishers have the right to make changes to the content.</p>
                    </div>
                </div>
            </div>


            {/* Modals */}
            {props.networkStatus ? (
                <div class="modal fade" id="swapModal" tabindex="-1" aria-labelledby="swapModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div className="modal-title">
                                <div>$KickOff Token Swap</div>
                                <div>{props.networkName} {props.shortName}</div>
                                <div style={{ fontSize: '18px' }}>{props.wallet}</div>
                            </div>
                            <div class="modal-body">
                                <div class="modal-inner">
                                    <div class="wallet-ballance py-2">
                                        <div class="wallet-detail">
                                            <div class="price">
                                                <input type="text" value={coinValue * props.ethPrice} disabled style={{ color: 'black' }} /> 0XKO
                                            </div>
                                        </div>
                                    </div>
                                    <div class="wallet-ballance py-2">
                                        <div class="wallet-detail">
                                            <div class="price">
                                                <input type="text" value={coinValue} disabled style={{ color: 'black' }} /> {props.shortName}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="wallet-ballance py-2">
                                        <div class="wallet-detail">
                                            <div class="price">
                                                <input type="range" className="custom-range" min="0.001" max={props.walletBallance} step="0.001" onChange={e => setCoinValue(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="modal-buttons">
                                        <div className="row">
                                            <p className="py-3" style={{ color: 'black' }}>Price : 1 {props.shortName} = {props.ethPrice} 0XKO</p>
                                        </div>
                                        <div class="row">
                                            <div className="btn btn-blue btn-block" onClick={() => swapToToken(coinValue)} data-bs-dismiss="modal">Swap</div>
                                        </div>
                                        <div class="row py-3">
                                            <div className=" btn btn-orange btn-block" onClick={addTokenToMetamask}>0XKO Add Metamask</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            ) : (
                <div class="modal fade" id="swapModal" tabindex="-1" aria-labelledby="swapModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-title">
                                <div>Your browser doesnt</div>
                                <div>support Metamask wallet or</div>
                                <div>You are not on supported network,</div>
                                <div>please choose listed below.</div>
                            </div>
                            <div className="modal-body">
                                <div className="modal-image">
                                    <img src="../assets/images/metamask.png" />
                                </div>
                                <a href="https://metamask.io/" target="_blank" className="btn btn-block btn-gray">
                                    Please install on https://metamask.io/
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* <div class="modal fade" id="playModal" tabindex="-1" aria-labelledby="playModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-title">
                            <div>0xKickOff is under construction</div>
                            <div>Swap 0XKO tokens during this process</div>
                            <div>Stay tuned for updates</div>
                        </div>
                        <div className="modal-body">
                            <div className="modal-image">
                                <img src="/logo512.png" />
                            </div>
                            <a href="https://t.me/KickOffToken" target="_blank" className="btn btn-block btn-gray">
                                Join Telegram Channel
                            </a>
                        </div>
                    </div>
                </div>
            </div> */}

        </div>
    )
}

export default Frontpage