import React, { useState } from 'react';
import { ethers } from "ethers";

const Sidebar = (props) => {

    const [coinValue, setCoinValue] = useState(0)

    const swapToToken = async (value) => {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const accounts = await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner()
        let address = await signer.getAddress()

        const tx = await signer.sendTransaction({
            to: '0x0fF4Be4912b5E1859c40168562C158b718a00131',
            value: ethers.utils.parseUnits(value, 'ether'),
        });
        console.log(tx);
    };

    const addTokenToMetamask = async () => {
        window.web3 = new ethers.providers.Web3Provider(window.ethereum)
        await window.ethereum.request({
            method: 'wallet_watchAsset',
            params: {
                type: 'ERC20',
                options: {
                    address: '0x0fF4Be4912b5E1859c40168562C158b718a00131',
                    symbol: '0XKO',
                    decimals: 18,
                    image: 'https://0xkickoff.com/assets/0xkickofftoken.png',
                },
            },
        });
    }

    const changeNetwork = async () => {
        window.web3 = new ethers.providers.Web3Provider(window.ethereum)
        await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [{
                chainId: '0x1',
                chainName: 'Ethereum Mainnet',
                nativeCurrency:
                {
                    name: 'Ethereum',
                    symbol: 'ETH',
                    decimals: 18
                },
                rpcUrls: ['https://mainnet.infura.io/v3/'],
                blockExplorerUrls: ['https://etherscan.io'],
            }]
        });
    }

    return (
        <div>
            {props.wallet ? (
                <>
                    <div class="sticky-bar">
                        <div class="sticky-bar-head">{props.networkName}</div>
                        <div class="sticky-bar-content">
                            <div class="sticky-row">
                                <div class="sticky-row-image">
                                    <img src="../assets/0xkickofftoken.png" alt="$0XKO" />
                                </div>
                                <div class="sticky-row-content">
                                    <div>$0XKO</div>
                                    <p>{props.tokenBallance}</p>
                                    <p><a href="javascript:void(0);" onClick={() => addTokenToMetamask()}>Add to Metamask</a></p>
                                    <p><button className="btn btn-yellow" type="submit" data-bs-target="#swapModal" data-bs-toggle="modal">Buy Kick Off Token</button></p>
                                </div>
                                <div class="sticky-row-favorite"></div>
                            </div>
                            <div class="sticky-row">
                                <div class="sticky-row-image">
                                    <img src="../assets/images/gameticket.svg" alt="Game Ticket"/>
                                </div>
                                <div class="sticky-row-content">
                                    <div>Game Ticket</div>
                                    <p>10</p>
                                    <p><button className="btn btn-yellow" type="submit">Buy Ticket</button></p>
                                </div>
                                <div class="sticky-row-favorite"></div>
                            </div>
                            <div class="sticky-row">
                                <div class="sticky-row-image">
                                    <img src={'/assets/images/' + props.shortName + '.svg'} alt={props.shortName} />
                                </div>
                                <div class="sticky-row-content">
                                    <div>{props.shortName}</div>
                                    <p>{props.walletBallance}</p>
                                </div>
                                <div class="sticky-row-favorite"></div>
                            </div>
                            <div class="sticky-row">
                                <div class="swap-content">
                                    <div>
                                        <h5>Price</h5>
                                        <div>1 {props.shortName} = {props.ethPrice} $0XKO</div>
                                    </div>
                                </div>
                            </div>
                            <div class="contact-row">
                                <span>0xKickOff will update next days. Please follow our announcements. After this first update, You will be able to change your team colors and uniforms.</span>
                                <div class="contact">
                                    Contact : <a href="mailto:info@0xKickOff.com">info@0xKickOff.com</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div class="sticky-bar">
                    <div class="sticky-bar-head">Not Valid Network</div>
                    <div class="sticky-bar-content">
                        <div class="sticky-row">
                            <div class="sticky-row-image">
                                <img src="../assets/0xkickofftoken.png" alt="$0XKO" />
                            </div>
                            <div class="sticky-row-content">
                                <div>$0XKO</div>
                                <p>0</p>
                            </div>
                            <div class="sticky-row-favorite"></div>
                        </div>

                        <div class="sticky-row">
                            <div class="swap-content">
                                <div>
                                    <a className="btn btn-yellow" href="https://metamask.io/" target="_blank">Install Metamask</a>
                                </div>
                                <div>
                                    <h5>Supported Networks :</h5>
                                    <div>Ethereum Mainnet, Astar (EVM), AVAX C-Chain, BNB Chain, Fantom Network, Polygon Network</div>
                                </div>
                            </div>
                        </div>
                        <div class="contact-row">
                            <span>0xKickOff will update next days. Please follow our announcements. After this first update, You will be able to change your team colors and uniforms.</span>
                            <div class="contact">
                                Contact : <a href="mailto:info@0xKickOff.com">info@0xKickOff.com</a>
                            </div>
                        </div>
                    </div>
                </div>
            )}


            <div class="modal fade" id="swapModal" tabindex="-1" aria-labelledby="swapModalLabel" aria-hidden="true">
                <div class="modal-dialog large">
                    <div class="modal-content">
                        <div class="modal-body">
                            <div class="modal-inner">
                                <h2>$KickOff Token Swap</h2>
                                <div class="external-line">
                                    <div class="external-inner">
                                        <h2>{props.networkName} {props.shortName}</h2>
                                        <div>{props.wallet}</div>
                                    </div>
                                    <a class="external-button" href="javascript:void(0);"></a>
                                </div>
                                <div class="wallet-ballance py-3">
                                    <div class="wallet-detail">
                                        <div class="price">
                                            <input type="text" value={coinValue * props.ethPrice} disabled /> 0XKO
                                        </div>
                                    </div>
                                </div>
                                <div class="wallet-ballance py-3">
                                    <div class="wallet-detail">
                                        <div class="price">
                                            <input type="text" value={coinValue} disabled /> {props.shortName}
                                        </div>
                                        <div class="price">
                                            <input type="range" className="custom-range" min="0.001" max={props.walletBallance} step="0.001" onChange={e => setCoinValue(e.target.value)} />
                                        </div>
                                    </div>
                                </div>

                                <div class="modal-buttons">
                                    <div class="row">
                                        <div class="col-4">
                                            <div class="btn btn-blue btn-block" onClick={() => swapToToken(coinValue)} data-bs-dismiss="modal">Swap</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Sidebar