import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import elImg from '../../assets/beden/1/ust.png'
import kolImg from '../../assets/beden/1/alt.png'
import bedenImg from '../../assets/beden/1/kafa.png'

import forma1sb from '../../assets/forma/1/sb.png'
import forma2sb from '../../assets/forma/2/sb.png'
import forma3sb from '../../assets/forma/3/sb.png'
import forma4sb from '../../assets/forma/4/sb.png'
import forma5sb from '../../assets/forma/5/sb.png'
import forma6sb from '../../assets/forma/6/sb.png'
import forma7sb from '../../assets/forma/7/sb.png'
import forma8sb from '../../assets/forma/8/sb.png'
import forma1kb from '../../assets/forma/1/kb.png'
import forma2kb from '../../assets/forma/2/kb.png'
import forma3kb from '../../assets/forma/3/kb.png'
import forma4kb from '../../assets/forma/4/kb.png'
import forma5kb from '../../assets/forma/5/kb.png'
import forma6kb from '../../assets/forma/6/kb.png'
import forma7kb from '../../assets/forma/7/kb.png'
import forma8kb from '../../assets/forma/8/kb.png'
import forma1sl from '../../assets/forma/1/sl.png'
import forma2sl from '../../assets/forma/2/sl.png'
import forma3sl from '../../assets/forma/3/sl.png'
import forma4sl from '../../assets/forma/4/sl.png'
import forma5sl from '../../assets/forma/5/sl.png'
import forma6sl from '../../assets/forma/6/sl.png'
import forma7sl from '../../assets/forma/7/sl.png'
import forma8sl from '../../assets/forma/8/sl.png'

import goz1Kahve from '../../assets/goz/1/kahve.png'
import goz1Kirmizi from '../../assets/goz/1/kirmizi.png'
import goz1Mavi from '../../assets/goz/1/mavi.png'
import goz1Yesil from '../../assets/goz/1/yesil.png'
import goz2Kahve from '../../assets/goz/2/kahve.png'
import goz2Kirmizi from '../../assets/goz/2/kirmizi.png'
import goz2Mavi from '../../assets/goz/2/mavi.png'
import goz2Yesil from '../../assets/goz/2/yesil.png'

import sacImg from '../../assets/sac/1/sari.png'

import ifadeImg from '../../assets/ifade/normal.png'
import soccerBg from '../../assets/bg.png'
import isimBg from '../../assets/bg-isim.png'

const Soccer = (props) => {

    const [gozImg, setGozImg] = useState(null);
    const [formaImg, setFormaImg] = useState(null);

    const selectJersey = () => {
        switch (parseInt(props.jersey)) {
            case 1:
                switch (props.color) {
                    case 'sb':
                        setFormaImg(forma1sb)
                        break;
                    case 'kb':
                        setFormaImg(forma1kb)
                        break;
                    case 'sl':
                        setFormaImg(forma1sl)
                        break;
                    default:
                        setFormaImg(forma1sb)
                        break;
                }
                break;
            case 2:
                switch (props.color) {
                    case 'sb':
                        setFormaImg(forma2sb)
                        break;
                    case 'kb':
                        setFormaImg(forma2kb)
                        break;
                    case 'sl':
                        setFormaImg(forma2sl)
                        break;
                    default:
                        setFormaImg(forma2sb)
                        break;
                }
                break;
            case 3:
                setFormaImg(forma3sb)
                break;
            case 4:
                setFormaImg(forma4sb)
                break;
            case 5:
                setFormaImg(forma5sb)
                break;
            case 6:
                setFormaImg(forma6sb)
                break;
            case 7:
                setFormaImg(forma7sb)
                break;
            case 8:
                setFormaImg(forma8sb)
                break;
            default:
                setFormaImg(forma1sb)
                break;
        }
    }

    const selectEyes = () => {
        switch (props.eyesC) {
            case 'kahve':
                if (props.eyes === 1) {
                    setGozImg(goz1Kahve)
                } else {
                    setGozImg(goz2Kahve)
                }
                break;
            case 'mavi':
                if (props.eyes === 1) {
                    setGozImg(goz1Mavi)
                } else {
                    setGozImg(goz2Mavi)
                }
                break;
            case 'yesil':
                if (props.eyes === 1) {
                    setGozImg(goz1Yesil)
                } else {
                    setGozImg(goz2Yesil)
                }
                break;
            case 'kirmizi':
                if (props.eyes === 1) {
                    setGozImg(goz1Kirmizi)
                } else {
                    setGozImg(goz2Kirmizi)
                }
                break;
            default:
                setGozImg(goz1Kahve)
                break;
        }
    }

    useEffect(() => {
        selectEyes()
        selectJersey()
    }, [props])

    const elStyle = {
        backgroundImage: 'url(' + elImg + ')',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: '300px',
        height: '300px',
        margin: '0'
    }

    const kolStyle = {
        backgroundImage: 'url(' + kolImg + ')',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: '300px',
        height: '300px',
        margin: '0'
    }

    const bedenStyle = {
        backgroundImage: 'url(' + bedenImg + ')',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: '300px',
        height: '300px',
        margin: '0'
    }

    const Forma = styled.div`
        background-image: url(${formaImg});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 300px;
        height: 300px;
        margin: 0;
    `;

    const Goz = styled.div`
        background-image: url(${gozImg});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 300px;
        height: 300px;
        margin: 0;
    `;


    const ifadeStyle = {
        backgroundImage: 'url(' + ifadeImg + ')',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: '300px',
        height: '300px',
        margin: '0'
    }

    const sacStyle = {
        backgroundImage: 'url(' + sacImg + ')',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: '300px',
        height: '300px',
        margin: '0'
    }

    const soccerStyle = {
        backgroundImage: 'url(' + soccerBg + ')',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: '300px',
        height: '300px',
        margin: '30px auto',
        display: 'table'
    }

    const isimStyle = {
        backgroundImage: 'url(' + isimBg + ')',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: '270px',
        height: '58px',
        margin: '0 auto',
        display: 'table',
        color: '#fff',
        lineHeight: '58px',
        textAlign: 'center',
        fontSize: '18px'
    }


    return (
        <div>
            <div style={soccerStyle}>
                <div style={elStyle}>
                    <div style={kolStyle}>
                        <div style={bedenStyle}>
                            <Forma>
                                <Goz>
                                    <div style={ifadeStyle}>
                                        <div style={sacStyle}></div>
                                    </div>
                                </Goz>
                            </Forma>
                        </div>
                    </div>
                </div>
            </div>
            <div style={isimStyle}>
                {props.name}
            </div>
        </div>
    )
}

export default Soccer