import React, { Fragment, useEffect, useCallback, useState } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";

import HeaderMenu from '../Components/HeaderMenu';
import Footer from '../Components/Footer';
import Sidebar from '../Components/Sidebar'
import Partners from '../Components/Partners'

const Game = (props) => {

    const [isWon, setIsWon] = useState(false);

    const { unityProvider, loadingProgression, isLoaded, requestFullscreen, addEventListener, removeEventListener, sendMessage } = useUnityContext({
        loaderUrl: "./Build/KickoffBuilds.loader.js",
        dataUrl: "./Build/KickoffBuilds.data",
        frameworkUrl: "./Build/KickoffBuilds.framework.js",
        codeUrl: "./Build/KickoffBuilds.wasm",
    });

    const handleGameOver = useCallback((isWon) => {
        setIsWon(true);
    }, []);

    function handleClickEnterFullscreen() {
        requestFullscreen(true);
    }

    const handSendWalletInfo = (walletId) => {
        sendMessage("GameController", "WalletInfo", walletId);
    }

    useEffect(() => {
        addEventListener("GameOver", handleGameOver);
        return () => {
            removeEventListener("GameOver", handleGameOver);
        };
    }, [addEventListener, removeEventListener, handleGameOver]);

    useEffect(() => {
        if (props.wallet) {
            handSendWalletInfo(props.wallet)
        }
    }, [props])

    return (
        <div>
            <HeaderMenu wallet={props.wallet} networkId={props.networkId} networkStatus={props.networkStatus} isSkale={props.isSkale} shortName={props.shortName} tokenAdd={props.tokenAdd} networkName={props.networkName} walletBallance={props.walletBallance} tokenBallance={props.tokenBallance} ethPrice={props.ethPrice} />
            <section id="content" class="without-image">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 mb-5">
                            <Sidebar wallet={props.wallet} networkId={props.networkId} networkStatus={props.networkStatus} isSkale={props.isSkale} shortName={props.shortName} tokenAdd={props.tokenAdd} networkName={props.networkName} walletBallance={props.walletBallance} tokenBallance={props.tokenBallance} ethPrice={props.ethPrice} />
                        </div>
                        <div className="col-lg-9">

                            <div className="product-list-title">
                                &nbsp;
                            </div>
                            <div className="product-list-content" style={{backgroundColor:"#231F20",color:"#F5F6F7"}}>
                                <Fragment>
                                    {!isLoaded && (
                                        <h2>Loading 0xKickOff... {Math.round(loadingProgression * 100)}%</h2>
                                    )}
                                    <Unity
                                        unityProvider={unityProvider}
                                        style={{ visibility: isLoaded ? "visible" : "hidden", width: '100%', height: 576 }}
                                        devicePixelRatio={devicePixelRatio}
                                    />
                                    {isWon === true && (
                                        <p>You won</p>
                                    )}
                                </Fragment>
                            </div>
                            <div className="product-list-title">
                                <button onClick={handleClickEnterFullscreen} className="btn btn-block btn-orange">Play Fullscreen</button>
                            </div>
                        </div>

                    </div>
                </div>
            </section >
            <Footer />
        </div >
    );
}

export default Game