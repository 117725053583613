import React from 'react'

const Footer = () => {
    return (
        <div>
            <footer id="footer">
                <div className="footer-top">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-12 col-md-auto mb-5">
                                <div className="footer-text">
                                    <h2>Follow us</h2>
                                    <p>Stay up to date with the latest developments by following 0xKickOff</p>
                                    <p>We take care to stay up to date.</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="footer-social">
                                    <div className="social">
                                        <a className="twitter" href="https://twitter.com/0xkickoff" target="_blank"></a>
                                    </div>
                                    <div className="social">
                                        <a className="telegram" href="https://t.me/KickOffToken" target="_blank"></a>
                                    </div>
                                    <div className="social">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="footer-bottom">
                        <div className="footer-menu">
                            <ul>
                                <li>
                                    <a href="/">ABOUT</a>
                                </li>
                                <li>
                                    <a href="/">TOKEN CONTRACT</a>
                                </li>
                                <li>
                                    <a href="/">NFT CONTRACT</a>
                                </li>
                                <li>
                                    <a href="/">CLUB</a>
                                </li>
                                <li>
                                    <a href="/">SQUAD</a>
                                </li>
                                <li>
                                    <a href="/">STADIUM</a>
                                </li>
                                <li>
                                    <a href="/">TRANSFER MARKET</a>
                                </li>
                            </ul>
                        </div>
                        <div className="copyright">
                            ©2023 0xKickOff All rights reserved.
                            <p>
                            0xKickOff.com is an online browser based soccer card game. It has parts that run on the blockchain and can be used with custom plugins. It is not a finance or investment company. It does not promise you profit. It is your responsibility to protect your personal information. Any rights acquired on the site or in the game can only be used within the game. Some parts of the game are narrated.Game producers or publishers have the right to make changes to the content.
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Footer