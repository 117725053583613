import React from 'react'
import HeaderMenu from '../../Components/HeaderMenu';
import Footer from '../../Components/Footer';
import Sidebar from '../../Components/Sidebar'
import Partners from '../../Components/Partners'

const TransferMarket = (props) => {
    return (
        <div>
            <HeaderMenu wallet={props.wallet} networkId={props.networkId} networkStatus={props.networkStatus} isSkale={props.isSkale} shortName={props.shortName} tokenAdd={props.tokenAdd} networkName={props.networkName} walletBallance={props.walletBallance} tokenBallance={props.tokenBallance} ethPrice={props.ethPrice} />
            <section className="dashboard-page" id="content" style={{ backgroundImage: "url('../assets/images/transfer-bg.png')" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 mb-5">
                            <Sidebar wallet={props.wallet} networkId={props.networkId} networkStatus={props.networkStatus} isSkale={props.isSkale} shortName={props.shortName} tokenAdd={props.tokenAdd} networkName={props.networkName} walletBallance={props.walletBallance} tokenBallance={props.tokenBallance} ethPrice={props.ethPrice} />
                        </div>
                        <div className="col-lg-9">
                            <div class="product-list">
                                <div class="product-list-title" style={{ textShadow: '2px 2px #000000' }}>
                                    Transfer Market
                                </div>
                            </div>
                            <div class="product-list-content">
                                <div class="showcase horizontal gold">
                                    <div class="showcase-inner">
                                        <div class="showcase-image">
                                        </div>
                                        <div class="showcase-content">
                                            <div class="showcase-nation">
                                                <img src="../assets/images/nations/italy.png" alt="Italy" />
                                            </div>
                                            <div class="showcase-name">
                                                Soccer-3448
                                            </div>
                                            <div class="showcase-info">
                                                <div>10</div>
                                                <div>DF</div>
                                            </div>
                                        </div>
                                        <div class="showcase-statistic">
                                            <div>ATT <strong>19</strong></div>
                                            <div>DEF <strong>6</strong></div>
                                            <div>STR <strong>10</strong></div>
                                        </div>
                                        <div class="showcase-button">
                                            <div class="btn btn-dark btn-block">SELL</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="showcase horizontal silver">
                                    <div class="showcase-inner">
                                        <div class="showcase-image">
                                        </div>
                                        <div class="showcase-content">
                                            <div class="showcase-nation">
                                                <img src="../assets/images/nations/italy.png" alt="Italy" />
                                            </div>
                                            <div class="showcase-name">
                                                Soccer-3448
                                            </div>
                                            <div class="showcase-info">
                                                <div>10</div>
                                                <div>DF</div>
                                            </div>
                                        </div>
                                        <div class="showcase-statistic">
                                            <div>ATT <strong>19</strong></div>
                                            <div>DEF <strong>6</strong></div>
                                            <div>STR <strong>10</strong></div>
                                        </div>
                                        <div class="showcase-button">
                                            <div class="btn btn-semi-dark btn-block">CANCEL</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="showcase horizontal bronze">
                                    <div class="showcase-inner">
                                        <div class="showcase-image">
                                        </div>
                                        <div class="showcase-content">
                                            <div class="showcase-nation">
                                                <img src="../assets/images/nations/italy.png" alt="Italy" />
                                            </div>
                                            <div class="showcase-name">
                                                Soccer-3448
                                            </div>
                                            <div class="showcase-info">
                                                <div>10</div>
                                                <div>DF</div>
                                            </div>
                                        </div>
                                        <div class="showcase-statistic">
                                            <div>ATT <strong>19</strong></div>
                                            <div>DEF <strong>6</strong></div>
                                            <div>STR <strong>10</strong></div>
                                        </div>
                                        <div class="showcase-button">
                                            <div class="btn btn-dark btn-block">SELL</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="filter-player">
                                <div class="filter-player-title">
                                    Filter Players on Sale
                                </div>
                                <div class="filter-player-content">
                                    <div class="row">
                                        <div class="col-md-3 col-6 mb-4 mb-md-0">
                                            <div class="dropdown custom-select">
                                                <button class="dropdown-toggle" type="button" id="filter-menu-1" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                                                    Price
                                                </button>
                                                <ul class="dropdown-menu" aria-labelledby="filter-menu-1">
                                                    <li>
                                                        <a class="dropdown-item filter-drop-list" href="javascript:void(0);" item-parent="filter-menu-1" item-value="Price">
                                                            <div class="custom-checkbox">
                                                                <input name="category" value="1" id="1" type="checkbox" />
                                                                <label for="1">11.11</label>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a class="dropdown-item filter-drop-list" href="javascript:void(0);" item-parent="filter-menu-2" item-value="Price">
                                                            <div class="custom-checkbox">
                                                                <input name="category" value="2" id="2" type="checkbox" />
                                                                <label for="2">22.22</label>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a class="dropdown-item filter-drop-list" href="javascript:void(0);" item-parent="filter-menu-3" item-value="Price">
                                                            <div class="custom-checkbox">
                                                                <input name="category" value="3" id="3" type="checkbox" />
                                                                <label for="3">33.33</label>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a class="dropdown-item filter-drop-list" href="javascript:void(0);" item-parent="filter-menu-4" item-value="Price">
                                                            <div class="custom-checkbox">
                                                                <input name="category" value="4" id="4" type="checkbox" />
                                                                <label for="4">44.44</label>
                                                            </div>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-md-3 col-6 mb-4 mb-md-0">
                                            <div class="dropdown custom-select">
                                                <button class="dropdown-toggle" type="button" id="filter-menu-5" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                                                    Best Players
                                                </button>
                                                <ul class="dropdown-menu" aria-labelledby="filter-menu-5">
                                                    <li>
                                                        <a class="dropdown-item filter-drop-list" href="javascript:void(0);" item-parent="filter-menu-5" item-value="Price">
                                                            <div class="custom-checkbox">
                                                                <input name="category" value="5" id="5" type="checkbox" />
                                                                <label for="5">Lionel</label>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a class="dropdown-item filter-drop-list" href="javascript:void(0);" item-parent="filter-menu-6" item-value="Price">
                                                            <div class="custom-checkbox">
                                                                <input name="category" value="6" id="6" type="checkbox" />
                                                                <label for="6">Andres</label>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a class="dropdown-item filter-drop-list" href="javascript:void(0);" item-parent="filter-menu-7" item-value="Price">
                                                            <div class="custom-checkbox">
                                                                <input name="category" value="7" id="7" type="checkbox" />
                                                                <label for="7">Messi</label>
                                                            </div>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-md-3 col-6 mb-4 mb-md-0">
                                            <div class="dropdown custom-select">
                                                <button class="dropdown-toggle" type="button" id="filter-menu-5" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                                                    All Position
                                                </button>
                                                <ul class="dropdown-menu" aria-labelledby="filter-menu-5">
                                                    <li>
                                                        <a class="dropdown-item filter-drop-list" href="javascript:void(0);" item-parent="filter-menu-8" item-value="Price">
                                                            <div class="custom-checkbox">
                                                                <input name="category" value="8" id="8" type="checkbox" />
                                                                <label for="8">Goalkeeper</label>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a class="dropdown-item filter-drop-list" href="javascript:void(0);" item-parent="filter-menu-9" item-value="Price">
                                                            <div class="custom-checkbox">
                                                                <input name="category" value="9" id="9" type="checkbox" />
                                                                <label for="9">Defender</label>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a class="dropdown-item filter-drop-list" href="javascript:void(0);" item-parent="filter-menu-10" item-value="Price">
                                                            <div class="custom-checkbox">
                                                                <input name="category" value="10" id="10" type="checkbox" />
                                                                <label for="10">Midfielder</label>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a class="dropdown-item filter-drop-list" href="javascript:void(0);" item-parent="filter-menu-11" item-value="Price">
                                                            <div class="custom-checkbox">
                                                                <input name="category" value="11" id="11" type="checkbox" />
                                                                <label for="11">Forward</label>
                                                            </div>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-md-3 col-6 mb-4 mb-md-0">
                                            <div class="dropdown custom-select">
                                                <button class="dropdown-toggle" type="button" id="filter-menu-5" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                                                    All Country
                                                </button>
                                                <ul class="dropdown-menu" aria-labelledby="filter-menu-5">
                                                    <li>
                                                        <a class="dropdown-item filter-drop-list" href="javascript:void(0);" item-parent="filter-menu-12" item-value="Price">
                                                            <div class="custom-checkbox">
                                                                <input name="category" value="12" id="12" type="checkbox" />
                                                                <label for="12">Turkey</label>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a class="dropdown-item filter-drop-list" href="javascript:void(0);" item-parent="filter-menu-13" item-value="Price">
                                                            <div class="custom-checkbox">
                                                                <input name="category" value="13" id="13" type="checkbox" />
                                                                <label for="13">Germany</label>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a class="dropdown-item filter-drop-list" href="javascript:void(0);" item-parent="filter-menu-14" item-value="Price">
                                                            <div class="custom-checkbox">
                                                                <input name="category" value="14" id="14" type="checkbox" />
                                                                <label for="14">England</label>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a class="dropdown-item filter-drop-list" href="javascript:void(0);" item-parent="filter-menu-15" item-value="Price">
                                                            <div class="custom-checkbox">
                                                                <input name="category" value="15" id="15" type="checkbox" />
                                                                <label for="15">Brasil</label>
                                                            </div>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default TransferMarket