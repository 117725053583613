import React from 'react'
import HeaderMenu from '../../Components/HeaderMenu';
import Footer from '../../Components/Footer';
import Sidebar from '../../Components/Sidebar'
import Partners from '../../Components/Partners'

const Stadium = (props) => {
    return (
        <div>
            <HeaderMenu wallet={props.wallet} networkId={props.networkId} networkStatus={props.networkStatus} isSkale={props.isSkale} shortName={props.shortName} tokenAdd={props.tokenAdd} networkName={props.networkName} walletBallance={props.walletBallance} tokenBallance={props.tokenBallance} ethPrice={props.ethPrice} />
            <section className="dashboard-page" id="content" style={{backgroundImage: "url('../assets/images/stadium-bg.png')"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 mb-5">
                            <Sidebar wallet={props.wallet} networkId={props.networkId} networkStatus={props.networkStatus} isSkale={props.isSkale} shortName={props.shortName} tokenAdd={props.tokenAdd} networkName={props.networkName} walletBallance={props.walletBallance} tokenBallance={props.tokenBallance} ethPrice={props.ethPrice} />
                        </div>
                        <div className="col-lg-9">
                            <div class="product-list">
                                <div class="product-list-title" style={{textShadow: '2px 2px #000000'}}>
                                    Stadium
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Stadium