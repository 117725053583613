import React from 'react'

const Header = (props) => {
    return (
        <div>
            <div id="mobile-navigation">
                <div className="navigation-content">
                    <div className="close-overlay" onclick="closeOverlay();"></div>
                    <ul>
                        <li>
                            <a href="/Club">Club</a>
                        </li>
                        <li>
                            <a href="/Squad">Squad</a>
                        </li>
                        <li>
                            <a href="/Stadium">Stadium</a>
                        </li>
                        <li>
                            <a href="/TransferMarket">Transfer Market</a>
                        </li>
                        {props.wallet ? (
                            <>

                                <li>
                                    <a href="javascript:void(0);" className="account-code btn btn-block btn-white" data-bs-toggle="modal" data-bs-target="#swapModal">{props.wallet.substr(0, 5)}......{props.wallet.substr(37, 42)}</a>

                                </li>
                                <li>
                                    <a href="javascript:void(0);" className="btn btn-block btn-orange" data-bs-toggle="modal" data-bs-target="#metaMaskModal">CREATE SOCCER NFT</a>

                                </li>
                            </>
                        ) : (
                            <>
                                <li>
                                    <a href="javascript:void(0);">Connect Wallet</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">CREATE SOCCER NFT</a>
                                </li>
                            </>
                        )}

                    </ul>
                </div>
            </div>
            <header id="header">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-md-3 col order-xl-1 order-2">
                            <div className="logo">
                                <a href="/Dashboard">
                                    <img src="../assets/images/logo.png" alt="0xKickOff" width="150" />
                                </a>
                            </div>
                        </div>
                        <div className="d-none d-xl-block col order-2">
                            <nav id="header-nav">
                                <ul>
                                    <li>
                                        <a href="/Club">Club</a>
                                    </li>
                                    <li>
                                        <a href="/Squad">Squad</a>
                                    </li>
                                    <li>
                                        <a href="/Stadium">Stadium</a>
                                    </li>
                                    <li>
                                        <a href="/TransferMarket">Transfer Market</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div className="d-none d-xl-block col-md-4 order-2">
                            <div className="header-buttons">
                                {props.wallet ? (
                                    <>
                                        <a href="javascript:void(0);" className="account-code btn btn-block btn-white" data-bs-toggle="modal" data-bs-target="#swapModal">{props.wallet.substr(0, 5)}......{props.wallet.substr(37, 42)}</a>
                                        <a href="javascript:void(0);" className="btn btn-block btn-orange" data-bs-toggle="modal" data-bs-target="#metaMaskModal">CREATE SOCCER NFT</a>&nbsp;&nbsp;&nbsp;
                                        <a href="/Game" className="btn btn-block btn-danger">PLAY</a>
                                    </>
                                ) : (
                                    <>
                                        <a href="javascript:void(0);" className="account-code btn btn-block btn-white" data-bs-toggle="modal" data-bs-target="#metaMaskModal">Connect Wallet</a>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="col-3 order-xl-2 order-1 d-xl-none align-items-center justify-content-center">
                            <div className="mobile-navigation" data-selector="toggle-bar" onclick="openNavigation('mobile-navigation');"></div>
                        </div>
                    </div>
                </div>
            </header>

            <div className="modal fade" id="metaMaskModal" tabindex="-1" aria-labelledby="metaMaskModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-title">
                            <div>Your browser doesnt</div>
                            <div>support Metamask wallet or</div>
                            <div>You are not on supported network.</div>
                        </div>
                        <div className="modal-body">
                            <div className="modal-image">
                                <img src="../assets/images/metamask.png" />
                            </div>
                            <a href="https://metamask.io/" target="_blank" className="btn btn-block btn-gray">
                                Please install on https://metamask.io/
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header